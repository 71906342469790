import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { default as logo } from '../assets/logomotel.jpg';
import { Button, Offcanvas } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../utils/redux/slices/userSlice';

export default function NavbarComponent({ children }) {
  const role = useSelector((state) => state.user.role);
  const username = useSelector((state) => state.user.username);
  const dispatch = useDispatch();
  return (
    <div>
      <Navbar expand={'sm'}>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              width="100"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${'sm'}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${'sm'}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${'sm'}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'sm'}`}>
                Menu
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="me-auto">
                {getLinks(role).map((item) => item)}
              </Nav>
              <Nav>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                >
                  <text style={{ marginRight: 20 }}>{username}</text>
                </div>
                <Button
                  variant={'outline-primary'}
                  onClick={() => dispatch(logout())}
                >
                  Salir
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    style={{ marginLeft: 10 }}
                  />
                </Button>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {children}
    </div>
  );
}

function getLinks(role) {
  switch (role) {
    case 'EMPLOYEE':
      return [<div></div>];
    case 'SUB_MANAGER':
      return [<Nav.Link href="/charges">Cargos</Nav.Link>];
    case 'MANAGER':
    case 'DEVELOPER':
      return [
        <Nav.Link href="/users">Usuarios</Nav.Link>,
        <Nav.Link href="/rooms">Habitaciones</Nav.Link>,
        <Nav.Link href="/charges">Cargos</Nav.Link>,
        <Nav.Link href="/discounts">Descuentos</Nav.Link>,
        <Nav.Link href="/movements">Movimientos</Nav.Link>,
        <Nav.Link href="/tickets">Tickets</Nav.Link>,
      ];
  }
}
