import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Modal,
  Row,
  ToggleButton,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios from '../utils/axios';
import { initiateAlert } from '../utils/redux/slices/alertSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function BlockModal({
  type,
  number,
  close,
  show,
  id,
  callback,
  status,
}) {
  const dispatch = useDispatch();
  const title = `Bloqueo en: ${type.name} #${number}`;
  const [field, setField] = useState('');

  async function closeModal() {
    close();
    await setTimeout(() => {
      setField('');
    }, 200);
  }

  async function handleBlock() {
    try {
      const response = await axios.post('/movement/block', {
        comment: field,
        roomId: id,
        currentStatus: status,
      });
      closeModal();
      dispatch(initiateAlert({ text: 'Bloqueo exitoso.', variant: 'success' }));
      callback();
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  const style = {
    confirmationText: {
      fontWeight: 700,
    },
  };

  return (
    <Modal show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Razón de Bloqueo:
        <Form.Control
          as="textarea"
          rows={5}
          value={field}
          onChange={({ target }) => setField(target.value)}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          style={{ marginRight: 5 }}
          variant="secondary"
          onClick={() => closeModal()}
        >
          Cancelar
        </Button>
        <Button
          variant="success"
          onClick={async () => {
            await handleBlock();
          }}
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function getSpanishType(type) {
  switch (type) {
    case 'SINGLE':
      return 'Sencilla';
    case 'JUNIOR':
      return 'Junior';
    case 'JACUZZI':
      return 'Jacuzzi';
  }
}
