import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import axios, { getToken, useAxios } from '../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { initiateAlert } from '../../utils/redux/slices/alertSlice';

export default function Discounts() {
  const dispatch = useDispatch();
  const [{ data, loading, error }] = useAxios('/discount', {
    useCache: false,
  });
  const [adding, setAdding] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [newFields, setNewFields] = useState(defaultDiscount);

  useEffect(() => {
    setNewFields(defaultDiscount);
  }, [adding]);

  useEffect(() => {
    if (loading) return;
    if (error) {
      console.log(error);
      alert('error');
      return;
    }
    setDiscounts(data);
  }, [loading]);

  async function handleAdd() {
    try {
      const response = await axios.post('/discount', newFields);
      setAdding(false);
      setDiscounts([response.data, ...discounts]);
      dispatch(
        initiateAlert({
          text: `Descuento ${response.data.name} añadido exitosamente`,
          variant: 'success',
        }),
      );
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  async function removeDiscount(discount) {
    try {
      const response = await axios.patch('/discount/archive', {
        id: discount.id,
      });
      setDiscounts(
        discounts.filter((item, index) => {
          if (item.id === discount.id) return false;
          else return true;
        }),
      );
      dispatch(
        initiateAlert({
          text: `Descuento ${discount.name} eliminado exitosamente`,
          variant: 'success',
        }),
      );
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  async function saveChanges(discount) {
    try {
      const response = await axios.patch('/discount/update', discount);
      dispatch(
        initiateAlert({
          text: `Descuento ${discount.name} ha sido actualizado`,
          variant: 'success',
        }),
      );
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  return (
    <div style={{ marginLeft: 10, marginRight: 10 }}>
      <Row style={{ marginBottom: 20 }}>
        <Col></Col>
        <Col lg xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <text
            style={{
              fontWeight: '700',
              fontSize: 30,
            }}
          >
            DESCUENTOS
          </text>
        </Col>
        <Col></Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <div>
          {adding ? (
            <Button variant={'danger'} onClick={() => setAdding(false)}>
              Cancelar
            </Button>
          ) : (
            <Button
              disabled={adding}
              variant={'success'}
              onClick={() => setAdding(true)}
            >
              Agregar Nuevo
            </Button>
          )}
        </div>
      </Row>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Porcentaje</th>
            <th>Guardar</th>
            <th>Borrar</th>
          </tr>
        </thead>
        <tbody>
          {adding ? (
            <tr>
              <td>
                <Form.Control
                  value={newFields.name}
                  onChange={({ target }) =>
                    setNewFields({ ...newFields, name: target.value })
                  }
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  min={0}
                  max={100}
                  value={newFields.percentage}
                  onChange={({ target }) =>
                    setNewFields({ ...newFields, percentage: target.value })
                  }
                />
              </td>
              <td>
                <Button
                  variant={'success'}
                  onClick={async () => await handleAdd()}
                >
                  Agregar
                </Button>
              </td>
              <td></td>
            </tr>
          ) : (
            <></>
          )}
          {discounts.map((item, index) => {
            return (
              <tr>
                <td>{item.name}</td>
                <td>
                  <Form.Control
                    type="number"
                    value={item.percentage}
                    min={0}
                    max={100}
                    onChange={({ target }) =>
                      setDiscounts(
                        discounts.map((itm, ind) => {
                          if (ind === index)
                            return { ...itm, percentage: target.value };
                          else return itm;
                        }),
                      )
                    }
                  />
                </td>
                <td>
                  <Button
                    variant={'primary'}
                    disabled={adding || item === data[index]}
                    onClick={async () => await saveChanges(item)}
                  >
                    Guardar
                  </Button>
                </td>
                <td>
                  <Button
                    variant={'danger'}
                    disabled={adding}
                    onClick={async () => await removeDiscount(item)}
                  >
                    Borrar
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

const dummyDiscounts = [
  {
    name: 'aceite',
    type: 'CONSUMABLE',
    price: 50,
  },
  {
    name: 'condon',
    type: 'CONSUMABLE',
    price: 50,
  },
];

const defaultDiscount = {
  name: '',
  percentage: 0,
};
