import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import axios, { getToken, useAxios } from '../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { initiateAlert } from '../../utils/redux/slices/alertSlice';

export default function Users() {
  const [{ data, loading, error }] = useAxios('/user', {
    useCache: false,
  });
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [adding, setAdding] = useState(false);
  const [newFields, setNewFields] = useState(defaultUser);
  const dispatch = useDispatch();
  async function handleAdd() {
    try {
      const response = await axios.post('/user', newFields);
      setAdding(false);
      setUsers([response.data, ...users]);
      dispatch(
        initiateAlert({
          text: `Usuario ${response.data.username} añadido exitosamente`,
          variant: 'success',
        }),
      );
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  async function removeUser(user) {
    try {
      const response = await axios.patch('/user/archive', { id: user.id });
      setUsers(
        users.filter((item, index) => {
          if (item.id === user.id) return false;
          else return true;
        }),
      );
      dispatch(
        initiateAlert({
          text: `Usuario ${user.username} eliminado exitosamente`,
          variant: 'success',
        }),
      );
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  async function resetPassword() {
    try {
      const response = await axios.patch('/user/reset', {
        id: selectedUser.id,
        password: newPassword,
      });
      closeModal();
      dispatch(
        initiateAlert({
          text: `Contraseña de usuario ${selectedUser.username} ha sido reseteada`,
          variant: 'success',
        }),
      );
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  async function saveChanges(user) {
    try {
      const response = await axios.patch('/user/update', user);
      dispatch(
        initiateAlert({
          text: `Usuario ${user.username} ha sido actualizado`,
          variant: 'success',
        }),
      );
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  function closeModal() {
    setShow(false);
  }
  function openModal(user) {
    setSelectedUser(user);
    setNewPassword('');
    setShow(true);
  }

  useEffect(() => {
    setNewFields(defaultUser);
  }, [adding]);

  useEffect(() => {
    if (loading) return;
    if (error) {
      console.log(error);
      alert('error');
      return;
    }
    setUsers(data);
  }, [loading]);

  return (
    <div style={{ marginLeft: 10, marginRight: 10 }}>
      <Row style={{ marginBottom: 20 }}>
        <Col></Col>
        <Col lg xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <text
            style={{
              fontWeight: '700',
              fontSize: 30,
            }}
          >
            USUARIOS
          </text>
        </Col>
        <Col></Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <div>
          {adding ? (
            <Button variant={'danger'} onClick={() => setAdding(false)}>
              Cancelar
            </Button>
          ) : (
            <Button
              disabled={adding}
              variant={'success'}
              onClick={() => setAdding(true)}
            >
              Agregar Nuevo
            </Button>
          )}
        </div>
      </Row>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Nombre</th>
            <th>Tipo</th>
            <th>Turno</th>
            <th>Contraseña</th>
            <th>Guardar</th>
            <th>Borrar</th>
          </tr>
        </thead>
        <tbody>
          {adding ? (
            <tr>
              <td>
                <Form.Control
                  value={newFields.username}
                  onChange={({ target }) =>
                    setNewFields({ ...newFields, username: target.value })
                  }
                />
              </td>
              <td>
                <Form.Control
                  value={newFields.name}
                  onChange={({ target }) =>
                    setNewFields({ ...newFields, name: target.value })
                  }
                />
              </td>
              <td>
                <Form.Select
                  value={newFields.type}
                  onChange={({ target }) =>
                    setNewFields({ ...newFields, type: target.value })
                  }
                >
                  <option value="EMPLOYEE">Empleado</option>
                  <option value="SUB_MANAGER">Sub-Manager</option>
                  <option value="MANAGER">Manager</option>
                  <option value="DEVELOPER">Programador</option>
                </Form.Select>
              </td>
              <td>
                <Form.Select
                  value={newFields.turn}
                  onChange={({ target }) =>
                    setNewFields({ ...newFields, turn: target.value })
                  }
                >
                  <option value="MORNING">Matutino</option>
                  <option value="EVENING">Vespertino</option>
                  <option value="NIGHT">Nocturno</option>
                  <option value="TEMP">Cubre Horas</option>
                  <option value="NONE">Ninguno</option>
                </Form.Select>
              </td>
              <td>
                <Form.Control
                  type="password"
                  autoComplete="off"
                  value={newFields.password}
                  onChange={({ target }) =>
                    setNewFields({ ...newFields, password: target.value })
                  }
                />
              </td>
              <td>
                <Button
                  variant={'success'}
                  onClick={async () => await handleAdd()}
                >
                  Agregar
                </Button>
              </td>
              <td></td>
            </tr>
          ) : (
            <></>
          )}
          {users.map((item, index) => {
            return (
              <tr>
                <td>{item.username}</td>
                <td>{item.name}</td>
                <td>
                  <Form.Select
                    value={item.type}
                    onChange={({ target }) =>
                      setUsers(
                        users.map((itm, ind) => {
                          if (ind === index)
                            return { ...itm, type: target.value };
                          else return itm;
                        }),
                      )
                    }
                    style={{ minWidth: 150 }}
                  >
                    <option value="EMPLOYEE">Empleado</option>
                    <option value="SUB_MANAGER">Sub-Manager</option>
                    <option value="MANAGER">Manager</option>
                    <option value="DEVELOPER">Programador</option>
                  </Form.Select>
                </td>
                <td>
                  <Form.Select
                    value={item.turn}
                    style={{ minWidth: 150 }}
                    onChange={({ target }) =>
                      setUsers(
                        users.map((itm, ind) => {
                          if (ind === index)
                            return { ...itm, turn: target.value };
                          else return itm;
                        }),
                      )
                    }
                  >
                    <option value="MORNING">Matutino</option>
                    <option value="EVENING">Vespertino</option>
                    <option value="NIGHT">Nocturno</option>
                    <option value="TEMP">Cubre Horas</option>
                    <option value="NONE">Ninguno</option>
                  </Form.Select>
                </td>
                <td>
                  <Button
                    disabled={adding}
                    onClick={() => openModal(item)}
                    variant={'warning'}
                  >
                    Reiniciar
                  </Button>
                </td>
                <td>
                  <Button
                    disabled={adding || item === data[index]}
                    variant={'primary'}
                    onClick={async () => await saveChanges(item)}
                  >
                    Guardar
                  </Button>
                </td>
                <td>
                  <Button
                    disabled={adding}
                    onClick={async () => await removeUser(item)}
                    variant={'danger'}
                  >
                    Borrar
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Modal show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>{`Cambio de Contraseña de ${selectedUser.username}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Nueva contraseña:
          <Form.Control
            type={'password'}
            value={newPassword}
            onChange={({ target }) => setNewPassword(target.value)}
          ></Form.Control>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginRight: 5 }}
            variant="secondary"
            onClick={() => closeModal()}
          >
            Cancelar
          </Button>
          <Button
            variant="success"
            onClick={async () => {
              await resetPassword();
            }}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const dummyUsers = [
  {
    user: 'dleber',
    name: 'Delber Medina',
    type: 'EMPLOYEE',
    turn: 'MORNING',
  },
  {
    user: 'alf',
    name: 'Alfredo',
    type: 'MANAGER',
    turn: 'NONE',
  },
];

const defaultUser = {
  username: '',
  name: '',
  type: 'EMPLOYEE',
  turn: 'NONE',
  password: '',
};
