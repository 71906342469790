import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faBan,
  faBroom,
  faRightFromBracket,
  faMoneyBill,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import CheckInModal from './CheckInModal';
import ChargesModal from './ChargesModal';
import BlockModal from './BlockModal';
import CancelModal from './CancelModal';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../utils/axios';
import { initiateAlert } from '../utils/redux/slices/alertSlice';

export default function Room({ initialStatus, number, type, id, callback }) {
  const [show, setShow] = useState(false);
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [showCharges, setShowCharges] = useState(false);
  const [showBlock, setShowBlock] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [status, setStatus] = useState(initialStatus);
  const [title, setTitle] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setTitle(`${type.name} #${number}: ${getSpanishStatus(status)}`);
    setShow(true);
  };
  return (
    <Col style={{ marginBottom: 10 }}>
      <Button
        style={{ width: '100%' }}
        variant={getVariant(status)}
        onClick={() => handleShow()}
      >
        <text>{number}</text>
        <br />
        <text>{type.name}</text>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <ModalBody
          id={id}
          status={status}
          openCheckIn={() => {
            setShow(false);
            setShowCheckIn(true);
          }}
          openCharges={() => {
            setShow(false);
            setShowCharges(true);
          }}
          openBlock={() => {
            setShow(false);
            setShowBlock(true);
          }}
          openCancel={() => {
            setShow(false);
            setShowCancel(true);
          }}
          changeStatus={(status) => {
            setShow(false);
            setTimeout(() => setStatus(status), 100);
          }}
        />
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
      <CheckInModal
        id={id}
        show={showCheckIn}
        close={() => setShowCheckIn(false)}
        type={type}
        number={number}
        callback={() => setStatus('OCCUPIED')}
      />
      <ChargesModal
        id={id}
        show={showCharges}
        close={() => setShowCharges(false)}
        type={type}
        number={number}
      />
      <BlockModal
        id={id}
        callback={() => setStatus('UNAVAILABLE')}
        show={showBlock}
        close={() => setShowBlock(false)}
        type={type}
        number={number}
        status={status}
      />
      <CancelModal
        id={id}
        callback={() => setStatus('FREE')}
        show={showCancel}
        close={() => setShowCancel(false)}
        type={type}
        number={number}
      />
    </Col>
  );
}

function getVariant(status) {
  switch (status) {
    case 'FREE':
      return 'success';
    case 'OCCUPIED':
      return 'danger';
    case 'DIRTY':
      return 'warning';
    case 'UNAVAILABLE':
      return 'dark';
  }
}

function getSpanishStatus(status) {
  switch (status) {
    case 'FREE':
      return 'Disponible';
    case 'OCCUPIED':
      return 'Ocupada';
    case 'DIRTY':
      return 'Sucia';
    case 'UNAVAILABLE':
      return 'Bloqueada';
  }
}

function ModalBody({
  status,
  openCheckIn,
  openCharges,
  openBlock,
  openCancel,
  changeStatus,
  id,
}) {
  const dispatch = useDispatch();
  async function toFree() {
    try {
      const response = await axios.post('/movement/ready', {
        roomId: id,
        currentStatus: status,
      });
      dispatch(initiateAlert({ text: 'Cambio exitoso.', variant: 'success' }));
      changeStatus('FREE');
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  async function toDirty() {
    try {
      const response = await axios.post('/movement/dirty', {
        roomId: id,
        currentStatus: status,
      });
      dispatch(initiateAlert({ text: 'Cambio exitoso.', variant: 'success' }));
      changeStatus('DIRTY');
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  async function checkOut() {
    try {
      const response = await axios.post('/movement/check_out', {
        roomId: id,
        currentStatus: status,
      });
      dispatch(
        initiateAlert({ text: 'Check out exitoso.', variant: 'success' }),
      );
      changeStatus('DIRTY');
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }
  switch (status) {
    case 'FREE':
      return (
        <Modal.Body>
          <Row style={{ marginBottom: 10 }}>
            <Button variant="success" onClick={() => openCheckIn()}>
              <FontAwesomeIcon icon={faCheck} style={{ marginRight: 10 }} />
              Check-in
            </Button>
          </Row>
          <Row>
            <Button variant="dark" onClick={() => openBlock()}>
              <FontAwesomeIcon icon={faBan} style={{ marginRight: 10 }} />
              Bloquear Habitación
            </Button>
          </Row>
        </Modal.Body>
      );
    case 'OCCUPIED':
      return (
        <Modal.Body>
          <Row style={{ marginBottom: 10 }}>
            <Button variant="success" onClick={() => checkOut()}>
              <FontAwesomeIcon
                icon={faRightFromBracket}
                style={{ marginRight: 10 }}
              />
              Check-out
            </Button>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Button variant="primary" onClick={() => openCharges()}>
              <FontAwesomeIcon icon={faMoneyBill} style={{ marginRight: 10 }} />
              Cargos
            </Button>
          </Row>
          <Row>
            <Button variant="danger" onClick={() => openCancel()}>
              <FontAwesomeIcon
                icon={faCircleXmark}
                style={{ marginRight: 10 }}
              />
              Cancelar Habitación
            </Button>
          </Row>
        </Modal.Body>
      );
    case 'DIRTY':
      return (
        <Modal.Body>
          <Row style={{ marginBottom: 10 }}>
            <Button variant="warning" onClick={() => toFree()}>
              <FontAwesomeIcon icon={faBroom} style={{ marginRight: 10 }} />
              Cambiar a Limpia
            </Button>
          </Row>
          <Row>
            <Button variant="dark" onClick={() => openBlock()}>
              <FontAwesomeIcon icon={faBan} style={{ marginRight: 10 }} />
              Bloquear Habitación
            </Button>
          </Row>
        </Modal.Body>
      );
    case 'UNAVAILABLE':
      return (
        <Modal.Body>
          <Row style={{ marginBottom: 10 }}>
            <Button variant="success" onClick={() => toFree()}>
              <FontAwesomeIcon icon={faCheck} style={{ marginRight: 10 }} />
              Cambiar a Lista
            </Button>
          </Row>
          <Row>
            <Button variant="warning" onClick={() => toDirty()}>
              <FontAwesomeIcon icon={faBroom} style={{ marginRight: 10 }} />
              Cambiar a Sucia
            </Button>
          </Row>
        </Modal.Body>
      );
  }
}
