import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Navbar from '../Navbar';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from '../screens/Dashboard';
import Home from '../screens/Home';
import Rooms from '../screens/Rooms';
import Users from '../screens/Users';
import RootSwitch from './RootSwitch';
import Charges from '../screens/Charges';
import Discounts from '../screens/Discounts';
import Movements from '../screens/Movements';
import Tickets from '../screens/Tickets';

export default function Router() {
  return <RouterProvider router={router} />;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootSwitch />,
    errorElement: <RootSwitch />,
  },
  {
    path: '/users',
    element: (
      <ProtectedRoute roles={['MANAGER', 'DEVELOPER']}>
        <Navbar>
          <Users />
        </Navbar>
      </ProtectedRoute>
    ),
  },
  {
    path: '/rooms',
    element: (
      <ProtectedRoute roles={['MANAGER', 'DEVELOPER']}>
        <Navbar>
          <Rooms />
        </Navbar>
      </ProtectedRoute>
    ),
  },
  {
    path: '/charges',
    element: (
      <ProtectedRoute roles={['MANAGER', 'DEVELOPER', 'SUB_MANAGER']}>
        <Navbar>
          <Charges />
        </Navbar>
      </ProtectedRoute>
    ),
  },
  {
    path: '/discounts',
    element: (
      <ProtectedRoute roles={['MANAGER', 'DEVELOPER']}>
        <Navbar>
          <Discounts />
        </Navbar>
      </ProtectedRoute>
    ),
  },
  {
    path: '/movements',
    element: (
      <ProtectedRoute roles={['MANAGER', 'DEVELOPER']}>
        <Navbar>
          <Movements />
        </Navbar>
      </ProtectedRoute>
    ),
  },
  {
    path: '/tickets',
    element: (
      <ProtectedRoute roles={['MANAGER', 'DEVELOPER']}>
        <Navbar>
          <Tickets />
        </Navbar>
      </ProtectedRoute>
    ),
  },
]);
