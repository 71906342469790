import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    accessToken: '',
    username: '',
    role: '',
  },
  reducers: {
    login: (state, action) => {
      state.username = action.payload.username;
      state.role = action.payload.role;
      state.accessToken = action.payload.accessToken;
    },
    logout: (state, action) => {
      state.username = '';
      state.role = '';
      state.accessToken = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
