import { createSlice } from '@reduxjs/toolkit';

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    text: '',
    variant: 'success',
    show: false,
  },
  reducers: {
    initiateAlert: (state, action) => {
      state.text = action.payload.text;
      state.variant = action.payload.variant;
      state.show = true;
    },
    hideAlert: (state, action) => {
      state.show = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { initiateAlert, hideAlert } = alertSlice.actions;

export default alertSlice.reducer;
