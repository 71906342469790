import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import axios, { getToken, useAxios } from '../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { initiateAlert } from '../../utils/redux/slices/alertSlice';

export default function Rooms() {
  const dispatch = useDispatch();
  const [{ data, loading, error }] = useAxios('/room/for_manager', {
    useCache: false,
  });
  const [types, setTypes] = useState([]);
  const [adding, setAdding] = useState(false);
  const [newFields, setNewFields] = useState({
    number: '',
    type: types[0]?.id,
  });
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    setNewFields({
      number: '',
      type: types[0]?.id,
    });
  }, [adding]);

  useEffect(() => {
    if (loading) return;
    if (error) {
      console.log(error);
      alert('error');
      return;
    }
    setRooms(data.rooms);
    setTypes(data.types);
  }, [loading]);

  async function handleAdd() {
    try {
      const response = await axios.post('/room', newFields);
      setAdding(false);
      setRooms([response.data, ...rooms]);
      dispatch(
        initiateAlert({
          text: `Habitación ${response.data.number} añadida exitosamente`,
          variant: 'success',
        }),
      );
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  async function removeRoom(room) {
    try {
      const response = await axios.patch('/room/archive', { id: room.id });
      setRooms(
        rooms.filter((item, index) => {
          if (item.id === room.id) return false;
          else return true;
        }),
      );
      dispatch(
        initiateAlert({
          text: `Habitación ${room.number} eliminada exitosamente`,
          variant: 'success',
        }),
      );
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  async function saveChanges(room) {
    try {
      const response = await axios.patch('/room/update', room);
      dispatch(
        initiateAlert({
          text: `Habitación ${room.number} ha sido actualizada`,
          variant: 'success',
        }),
      );
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  return (
    <div style={{ marginLeft: 10, marginRight: 10 }}>
      <Row style={{ marginBottom: 20 }}>
        <Col></Col>
        <Col lg xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <text
            style={{
              fontWeight: '700',
              fontSize: 30,
            }}
          >
            HABITACIONES
          </text>
        </Col>
        <Col></Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <div>
          {adding ? (
            <Button variant={'danger'} onClick={() => setAdding(false)}>
              Cancelar
            </Button>
          ) : (
            <Button
              disabled={adding}
              variant={'success'}
              onClick={() => setAdding(true)}
            >
              Agregar Nuevo
            </Button>
          )}
        </div>
      </Row>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Numero</th>
            <th>Tipo</th>
            <th>Guardar</th>
            <th>Borrar</th>
          </tr>
        </thead>
        <tbody>
          {adding ? (
            <tr>
              <td>
                <Form.Control
                  value={newFields.number}
                  onChange={({ target }) =>
                    setNewFields({ ...newFields, number: target.value })
                  }
                />
              </td>
              <td>
                <Form.Select
                  style={{ minWidth: 150 }}
                  value={newFields.type}
                  onChange={({ target }) =>
                    setNewFields({ ...newFields, type: target.value })
                  }
                >
                  {data.types?.map((item) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </Form.Select>
              </td>
              <td>
                <Button
                  variant={'success'}
                  onClick={async () => await handleAdd()}
                >
                  Agregar
                </Button>
              </td>
              <td></td>
            </tr>
          ) : (
            <></>
          )}
          {rooms.map((item, index) => {
            return (
              <tr>
                <td>{item.number}</td>
                <td>
                  <Form.Select
                    style={{ minWidth: 120 }}
                    value={item.type.id}
                    onChange={({ target }) =>
                      setRooms(
                        rooms.map((itm, ind) => {
                          if (ind === index)
                            return { ...itm, type: target.value };
                          else return itm;
                        }),
                      )
                    }
                  >
                    {data.types.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </Form.Select>
                </td>
                <td>
                  <Button
                    disabled={adding || item === data.rooms[index]}
                    variant={'primary'}
                    onClick={async () => await saveChanges(item)}
                  >
                    Guardar
                  </Button>
                </td>
                <td>
                  <Button
                    disabled={adding}
                    variant={'danger'}
                    onClick={async () => await removeRoom(item)}
                  >
                    Borrar
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

const dummyRoom = [
  {
    number: '1',
    type: 'SINGLE',
  },
  {
    number: '2',
    type: 'SINGLE',
  },
];

const defaultRoom = {
  number: '',
  type: '',
};
