import { Switch, Route, Redirect } from 'react-router-dom';
import Router from './components/router/Router';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { myPersistor, myStore } from './utils/redux/store';
import MyAlert from './components/Alert';

export default function App() {
  return (
    <Provider store={myStore}>
      <PersistGate loading={null} persistor={myPersistor}>
        <MyAlert />
        <Router />
      </PersistGate>
    </Provider>
  );
}
