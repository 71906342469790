import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import axios, { getToken, useAxios } from '../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { initiateAlert } from '../../utils/redux/slices/alertSlice';

export default function Movements() {
  const [movements, setMovements] = useState([]);
  const [date, setDate] = useState('');
  const dispatch = useDispatch();

  async function handleSearch() {
    try {
      const response = await axios.get(`/movement?date=${date}`);
      setMovements(response.data);
      if (response.data.length === 0)
        dispatch(
          initiateAlert({
            text: 'No existen movimientos este dia!',
            variant: 'info',
          }),
        );
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }
  return (
    <div style={{ marginLeft: 10, marginRight: 10 }}>
      <Row style={{ marginBottom: 20 }}>
        <Col></Col>
        <Col lg xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <text
            style={{
              fontWeight: '700',
              fontSize: 30,
            }}
          >
            MOVIMIENTOS
          </text>
        </Col>
        <Col></Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col lg={2} sm={6} xs={6}>
          <Form.Control
            style={{}}
            type="date"
            value={date}
            onChange={({ target }) => setDate(target.value)}
          />
        </Col>
        <Col lg={1} sm={6} xs={6}>
          <Button
            variant={'primary'}
            onClick={async () => await handleSearch()}
          >
            Buscar
          </Button>{' '}
        </Col>
      </Row>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Hora</th>
            <th>Habitacion</th>
            <th>Inicial</th>
            <th>Final</th>
            <th>Usuario</th>
            <th>Comentario</th>
          </tr>
        </thead>
        <tbody>
          {movements.map((item, index) => {
            const time = new Date(item.created);
            const hour = time.getHours();
            const minutes = time.getMinutes();
            return (
              <tr>
                <td>{`${putZeroBehind(hour)}:${putZeroBehind(minutes)}`}</td>
                <td>{item.room?.number}</td>
                <td>
                  <Button
                    disabled
                    variant={getVariant(item.initialState)}
                    style={{ width: 100 }}
                  >
                    {getSpanishStatus(item.initialState)}
                  </Button>
                </td>
                <td>
                  <Button
                    disabled
                    variant={getVariant(item.endingState)}
                    style={{ width: 100 }}
                  >
                    {getSpanishStatus(item.endingState)}
                  </Button>
                </td>
                <td>{item.author?.name}</td>
                <td>{item.comment}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

function putZeroBehind(number) {
  if (number < 10) return `0${number}`;
  else return number;
}

function getSpanishStatus(status) {
  switch (status) {
    case 'FREE':
      return 'Disponible';
    case 'OCCUPIED':
      return 'Ocupada';
    case 'DIRTY':
      return 'Sucia';
    case 'UNAVAILABLE':
      return 'Bloqueada';
  }
}

function getVariant(status) {
  switch (status) {
    case 'FREE':
      return 'success';
    case 'OCCUPIED':
      return 'danger';
    case 'DIRTY':
      return 'warning';
    case 'UNAVAILABLE':
      return 'dark';
  }
}
