import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  ToggleButton,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios, { useAxios } from '../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { initiateAlert } from '../utils/redux/slices/alertSlice';

export default function ChargesModal({ type, number, close, show, id }) {
  const title = `Cargos en: ${type.name} #${number}`;
  const dispatch = useDispatch();

  const [{ data, loading, error }] = useAxios('/charge', {
    useCache: false,
  });
  const [confirmation, setConfirmation] = useState(false);
  const [fields, setFields] = useState(defaultFields);
  const [total, setTotal] = useState(0);
  const [charges, setCharges] = useState([]);

  useEffect(() => {
    if (loading) return;
    if (error) {
      console.log(error);
      alert('error');
      return;
    }
    setCharges(data);
  }, [loading]);

  useEffect(() => {
    let total = 0;
    for (let i = 0; i < fields.charges.length; i++) {
      if (fields.charges[i].quantity < 1) continue;
      if (fields.charges[i].product === '') continue;
      const thisCharge = charges.find(
        (itm) => itm.id === fields.charges[i].product,
      );
      total = total + thisCharge.price * fields.charges[i].quantity;
    }
    setTotal(total);
  }, [fields]);

  async function closeModal() {
    close();
    await setTimeout(() => {
      setConfirmation(false);
      setFields(defaultFields);
    }, 200);
  }

  async function handleTicket() {
    try {
      const response = axios.post('/ticket', {
        charges: fields.charges,
        roomId: id,
        total: total,
      });
      closeModal();
      dispatch(initiateAlert({ text: 'Cargo exitoso!', variant: 'success' }));
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  const style = {
    confirmationText: {
      fontWeight: 700,
    },
  };

  return (
    <Modal
      show={show}
      onHide={() => closeModal()}
      size={confirmation ? 'md' : 'lg'}
      fullscreen={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!confirmation ? (
          <div>
            {fields.charges.map((item, index, array) => {
              return (
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  <Col lg={1}>Tipo:</Col>
                  <Col lg={3}>
                    <Form.Select
                      value={item.type}
                      onChange={({ target }) =>
                        setFields({
                          ...fields,
                          charges: array.map((itm, ind) => {
                            if (ind === index)
                              return { ...itm, type: target.value };
                            else return itm;
                          }),
                        })
                      }
                    >
                      <option value="CONSUMABLE">Consumible</option>
                      <option value="DAMAGE">Daño</option>
                      <option value="TIME">Tiempo</option>
                      <option value="PERSON">Persona</option>
                    </Form.Select>
                  </Col>
                  <Col lg={1}>Cargo:</Col>
                  <Col lg={4}>
                    <Form.Select
                      value={item.product}
                      onChange={({ target }) =>
                        setFields({
                          ...fields,
                          charges: array.map((itm, ind) => {
                            if (ind === index)
                              return { ...itm, product: target.value };
                            else return itm;
                          }),
                        })
                      }
                    >
                      <option value={''}>...</option>
                      {charges.map((itm) => {
                        if (itm.type === item.type)
                          return <option value={itm.id}>{itm.name}</option>;
                      })}
                    </Form.Select>
                  </Col>
                  <Col lg={3}>
                    <InputGroup>
                      <Button
                        variant="outline-danger"
                        disabled={item.quantity <= 1}
                        onClick={() =>
                          setFields({
                            ...fields,
                            charges: array.map((itm, ind) => {
                              if (ind === index)
                                return { ...itm, quantity: itm.quantity - 1 };
                              else return itm;
                            }),
                          })
                        }
                      >
                        -
                      </Button>
                      <Form.Control
                        type="number"
                        min={1}
                        default={1}
                        value={item.quantity}
                        onChange={({ target }) =>
                          setFields({
                            ...fields,
                            charges: array.map((itm, ind) => {
                              if (ind === index)
                                return { ...itm, quantity: target.value };
                              else return itm;
                            }),
                          })
                        }
                      />
                      <Button
                        variant="outline-success"
                        onClick={() =>
                          setFields({
                            ...fields,
                            charges: array.map((itm, ind) => {
                              if (ind === index)
                                return {
                                  ...itm,
                                  quantity: parseInt(itm.quantity) + 1,
                                };
                              else return itm;
                            }),
                          })
                        }
                      >
                        +
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              );
            })}
            <Row>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <div>
                  <InputGroup>
                    <Button
                      style={{ width: 150 }}
                      variant="success"
                      onClick={() =>
                        setFields({
                          ...fields,
                          charges: [
                            ...fields.charges,
                            defaultFields.charges[0],
                          ],
                        })
                      }
                    >
                      + Más Cargos
                    </Button>

                    <Button
                      style={{ width: 150 }}
                      variant="danger"
                      disabled={fields.charges.length === 1}
                      onClick={() =>
                        setFields({
                          ...fields,
                          charges: fields.charges.filter(
                            (item, index, array) => {
                              if (index === array.length - 1) return false;
                              else return true;
                            },
                          ),
                        })
                      }
                    >
                      - Menos Cargos
                    </Button>
                  </InputGroup>
                </div>
              </div>
            </Row>
          </div>
        ) : (
          <div>
            <Row>
              <Col xs={7}>
                <text style={style.confirmationText}>Cargo</text>
              </Col>
              <Col xs={3}>
                <text style={style.confirmationText}>Cantidad</text>
              </Col>
              <Col xs={2}>
                <text style={style.confirmationText}>Total</text>
              </Col>
            </Row>
            {fields.charges.map((item, index) => {
              const thisCharge = charges.find((itm) => itm.id === item.product);
              return (
                <Row>
                  <Col xs={7}>{thisCharge.name}</Col>
                  <Col xs={3}>{item.quantity}</Col>
                  <Col xs={2}>{item.quantity * thisCharge.price}</Col>
                </Row>
              );
            })}
            <Row
              style={{
                marginTop: 10,
                borderTop: 'solid',
                borderTopWidth: 0.5,
              }}
            >
              <Col xs={10}>
                <text style={style.confirmationText}>Total a Pagar</text>
              </Col>
              <Col xs={2}>{total}</Col>
            </Row>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer
        style={
          confirmation
            ? { display: 'flex', justifyContent: 'space-between' }
            : {}
        }
      >
        {confirmation ? (
          <Button variant="primary" onClick={() => setConfirmation(false)}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 10 }} />
            Atras
          </Button>
        ) : (
          <></>
        )}
        <div>
          <Button
            style={{ marginRight: 5 }}
            variant="secondary"
            onClick={() => closeModal()}
          >
            Cancelar
          </Button>
          <Button
            variant="success"
            onClick={() => {
              if (!confirmation) setConfirmation(true);
              else {
                handleTicket();
              }
            }}
          >
            Aceptar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const defaultFields = {
  charges: [
    {
      type: 'CONSUMABLE',
      product: '',
      quantity: 1,
    },
  ],
};

function getSpanishType(type) {
  switch (type) {
    case 'SINGLE':
      return 'Sencilla';
    case 'JUNIOR':
      return 'Junior';
    case 'JACUZZI':
      return 'Jacuzzi';
  }
}
