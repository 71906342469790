import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import axios, { getToken, useAxios } from '../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { initiateAlert } from '../../utils/redux/slices/alertSlice';

export default function Tickets() {
  const [total, setTotal] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [date, setDate] = useState('');
  const dispatch = useDispatch();

  async function handleSearch() {
    try {
      const response = await axios.get(`/ticket?date=${date}`);
      setTickets(response.data);
      let total = 0;
      for (let i = 0; i < response.data.length; i++) {
        total = total + response.data[i].total;
      }
      setTotal(total);
      if (response.data.length === 0)
        dispatch(
          initiateAlert({
            text: 'No existen tickets este dia!',
            variant: 'info',
          }),
        );
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }
  return (
    <div style={{ marginLeft: 10, marginRight: 10 }}>
      <Row style={{ marginBottom: 20 }}>
        <Col></Col>
        <Col lg xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <text
            style={{
              fontWeight: '700',
              fontSize: 30,
            }}
          >
            TICKETS
          </text>
        </Col>
        <Col></Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col lg={2} sm={6} xs={6}>
          <Form.Control
            style={{}}
            type="date"
            value={date}
            onChange={({ target }) => setDate(target.value)}
          />
        </Col>
        <Col lg={1} sm={6} xs={6}>
          <Button
            variant={'primary'}
            onClick={async () => await handleSearch()}
          >
            Buscar
          </Button>{' '}
        </Col>
      </Row>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Hora</th>
            <th>Habitacion</th>
            <th>Check in</th>
            <th>Check out</th>
            <th>Consumo</th>
          </tr>
        </thead>
        <tbody>
          {tickets.map((item, index) => {
            const time = new Date(item.created);
            const hour = time.getHours();
            const minutes = time.getMinutes();

            const checkInTime = new Date(item.visit.created);
            const modifiedDate = new Date(item.visit.modified);
            return (
              <tr>
                <td>{`${putZeroBehind(hour)}:${putZeroBehind(minutes)}`}</td>
                <td>{item.visit?.movement?.room?.number}</td>
                <td>
                  <text>{`${putZeroBehind(
                    checkInTime.getHours(),
                  )}:${putZeroBehind(checkInTime.getMinutes())}`}</text>
                </td>
                <td>
                  {item.visit?.checkedOut ? (
                    <text>{`${putZeroBehind(
                      modifiedDate.getHours(),
                    )}:${putZeroBehind(modifiedDate.getMinutes())}`}</text>
                  ) : item.visit?.canceled ? (
                    <div>
                      <Button
                        disabled
                        variant={'danger'}
                        style={{ width: 100 }}
                      >
                        Cancelado
                      </Button>
                      <text>{`${putZeroBehind(
                        modifiedDate.getHours(),
                      )}:${putZeroBehind(modifiedDate.getMinutes())}`}</text>
                    </div>
                  ) : (
                    <Button disabled variant={'warning'} style={{ width: 100 }}>
                      En curso
                    </Button>
                  )}
                </td>
                <td>{item.total}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {`Total: ${total}`}
    </div>
  );
}

function putZeroBehind(number) {
  if (number < 10) return `0${number}`;
  else return number;
}
