import React from 'react';
import { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { hideAlert } from '../utils/redux/slices/alertSlice';

export default function MyAlert() {
  const text = useSelector((state) => state.alert.text);
  const variant = useSelector((state) => state.alert.variant);
  const show = useSelector((state) => state.alert.show);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!show) return;
    setTimeout(() => {
      dispatch(hideAlert());
    }, 5000);
  }, [show]);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 80,
        position: 'absolute',
        width: '100%',
        paddingLeft: 50,
        paddingRight: 50,
      }}
    >
      <Alert
        style={{}}
        show={show}
        variant={variant}
        dismissible
        onClose={() => dispatch(hideAlert())}
      >
        {text}
      </Alert>
    </div>
  );
}
