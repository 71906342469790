import React from 'react';
import { useSelector } from 'react-redux';
import NavbarComponent from '../Navbar';
import Dashboard from '../screens/Dashboard';
import Home from '../screens/Home';

export default function RootSwitch() {
  const role = useSelector((state) => state.user.role);

  if (role === '') return <Home />;

  return (
    <NavbarComponent>
      <Dashboard />
    </NavbarComponent>
  );
}
