import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Modal,
  Row,
  ToggleButton,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios, { useAxios } from '../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { initiateAlert } from '../utils/redux/slices/alertSlice';

export default function CheckInModal({
  type,
  number,
  close,
  show,
  id,
  callback,
}) {
  const title = `Check-In: ${type.name} #${number}`;
  const [{ data, loading, error }] = useAxios('/discount', {
    useCache: false,
  });
  const [radio, setRadio] = useState('vehicle');
  const [confirmation, setConfirmation] = useState(false);
  const [fields, setFields] = useState(defaultFields);
  const [discounts, setDiscounts] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    setFields({ ...defaultFields, discountId: fields.discountId });
  }, [radio]);

  useEffect(() => {
    const decimal = fields.discountId
      ? discounts.filter((item) => {
          if (fields.discountId === item.id) return true;
          else return false;
        })[0].percentage / 100
      : 0;
    setFields({
      ...fields,
      total: type.price - (type.price * decimal).toFixed(2),
    });
  }, [confirmation]);

  useEffect(() => {
    if (loading) return;
    if (error) {
      console.log(error);
      alert('error');
      return;
    }
    setDiscounts(data);
  }, [loading]);

  async function closeModal() {
    close();
    await setTimeout(() => {
      setConfirmation(false);
      setFields(defaultFields);
      setRadio('vehicle');
    }, 200);
  }

  const style = {
    confirmationText: {
      fontWeight: 700,
    },
  };

  async function handleCheckIn() {
    try {
      const response = await axios.post('/movement/check_in', {
        ...fields,
        roomId: id,
      });
      closeModal();
      dispatch(
        initiateAlert({ text: 'Check in exitoso!', variant: 'success' }),
      );
      callback();
    } catch (e) {
      console.log(e);
      alert('sucedio un error');
    }
  }

  return (
    <Modal show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!confirmation ? (
          <div>
            <Row
              style={{
                marginBottom: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Tipo de datos:
              <ButtonGroup style={{ width: 240 }}>
                <ToggleButton
                  style={{ width: 120 }}
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  value={'person'}
                  checked={'person' === radio}
                  onClick={() => setRadio('person')}
                >
                  {'Personales'}
                </ToggleButton>
                <ToggleButton
                  style={{ width: 120 }}
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  value={'vehicle'}
                  checked={'vehicle' === radio}
                  onClick={() => setRadio('vehicle')}
                >
                  {'Vehículo'}
                </ToggleButton>
              </ButtonGroup>
            </Row>
            {radio === 'vehicle' ? (
              <div style={{ marginRight: 10, marginLeft: 10 }}>
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20,
                  }}
                >
                  Transporte:
                  <Form.Select
                    style={{ width: 150, marginLeft: 10 }}
                    value={fields.vehicle}
                    onChange={(e) =>
                      setFields({ ...fields, vehicle: e.target.value })
                    }
                  >
                    <option value="CAR">Automovil</option>
                    <option value="MOTORCYCLE">Motocicleta</option>
                  </Form.Select>
                </Row>
                <Row lg={2} sm={1}>
                  <Row
                    style={{
                      marginBottom: 20,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Matricula:
                    <Form.Control
                      value={fields.plates}
                      onChange={({ target }) =>
                        setFields({ ...fields, plates: target.value })
                      }
                      style={{ width: 100, marginLeft: 20 }}
                    ></Form.Control>
                  </Row>

                  <Row
                    style={{
                      marginBottom: 20,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Marca:
                    <Form.Control
                      value={fields.brand}
                      onChange={({ target }) =>
                        setFields({ ...fields, brand: target.value })
                      }
                      style={{ width: 100, marginLeft: 20 }}
                    ></Form.Control>
                  </Row>
                </Row>
                <Row lg={2} sm={1}>
                  <Row
                    style={{
                      marginBottom: 20,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Modelo:
                    <Form.Control
                      value={fields.model}
                      onChange={({ target }) =>
                        setFields({ ...fields, model: target.value })
                      }
                      style={{ width: 100, marginLeft: 20 }}
                    ></Form.Control>
                  </Row>

                  <Row
                    style={{
                      marginBottom: 20,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Color:
                    <Form.Control
                      value={fields.color}
                      onChange={({ target }) =>
                        setFields({ ...fields, color: target.value })
                      }
                      style={{ width: 100, marginLeft: 20 }}
                    ></Form.Control>
                  </Row>
                </Row>
              </div>
            ) : (
              <div>
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  Nombre:
                  <Form.Control
                    value={fields.name}
                    onChange={({ target }) =>
                      setFields({ ...fields, name: target.value })
                    }
                    style={{}}
                  ></Form.Control>
                </Row>
              </div>
            )}
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 20,
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              Descuento:
              <Form.Select
                style={{}}
                value={fields.discountId}
                onChange={({ target }) =>
                  setFields({
                    ...fields,
                    discountId: target.value,
                  })
                }
              >
                <option value="">Ninguno</option>
                {discounts.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </Form.Select>
            </Row>
          </div>
        ) : (
          <div>
            <div>
              {radio === 'vehicle' ? (
                <div>
                  <div>
                    <text style={style.confirmationText}>{'Transporte: '}</text>
                    {fields.vehicle === 'CAR' ? 'Automovil' : 'Motocicleta'}
                  </div>
                  <div>
                    <text style={style.confirmationText}>{'Matrícula: '}</text>
                    {fields.plates}
                  </div>
                  <div>
                    <text style={style.confirmationText}>{'Marca: '}</text>
                    {fields.brand}
                  </div>
                  <div>
                    <text style={style.confirmationText}>{'Modelo: '}</text>
                    {fields.model}
                  </div>
                  <div>
                    <text style={style.confirmationText}>{'Color: '}</text>
                    {fields.color}
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <text style={style.confirmationText}>{'Nombre: '}</text>
                    {fields.name}
                  </div>
                </div>
              )}
              <div style={{ marginTop: 10 }}>
                <text style={style.confirmationText}>{'Descuento: '}</text>
                {fields.discountId
                  ? discounts.filter((item) => {
                      if (fields.discountId == item.id) return true;
                      else return false;
                    })[0].name
                  : 'n/a'}
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <div>
                <text style={style.confirmationText}>{'Total: '}</text>
                {fields.total}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer
        style={
          confirmation
            ? { display: 'flex', justifyContent: 'space-between' }
            : {}
        }
      >
        {confirmation ? (
          <Button variant="primary" onClick={() => setConfirmation(false)}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 10 }} />
            Atras
          </Button>
        ) : (
          <></>
        )}
        <div>
          <Button
            style={{ marginRight: 5 }}
            variant="secondary"
            onClick={() => closeModal()}
          >
            Cancelar
          </Button>
          <Button
            variant="success"
            onClick={async () => {
              if (!confirmation) setConfirmation(true);
              else {
                await handleCheckIn();
              }
            }}
          >
            Aceptar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const defaultFields = {
  vehicle: 'CAR',
  plates: '',
  brand: '',
  model: '',
  color: '',
  name: '',
  discountId: '',
  total: 0,
};
