import { default as logo } from '../../assets/logomotel.jpg';
import { WINDOW_HEIGHT } from '../../utils/globals';
import { Form, Button, Container, Row, Image, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import Room from '../Room';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useAxios } from '../../utils/axios';

export default function Dashboard() {
  const [{ data, loading, error }] = useAxios('/room', {
    useCache: false,
  });

  const [rooms, setRooms] = useState([]);
  useEffect(() => {
    if (loading) return;
    if (error) {
      console.log(error);
      alert('error');
      return;
    }
    setRooms(data);
  }, [loading]);

  return (
    <Container style={{ paddingTop: 50 }}>
      <Row style={{ marginBottom: 20 }}>
        <Col></Col>
        <Col lg xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <text style={style.title}>Habitaciones</text>
        </Col>
        <Col></Col>
      </Row>
      <Row xs={2} sm={2} md={3} lg={4} xl={4} style={{ marginBottom: 10 }}>
        {rooms.map((item, index) => {
          return (
            <Room
              id={item.id}
              initialStatus={item.availability}
              number={item.number}
              type={item.type}
            />
          );
        })}
      </Row>
      <Row></Row>
      <Row></Row>
    </Container>
  );
}

const style = {
  title: {
    fontWeight: '700',
    fontSize: 30,
  },
};

const dummyRooms = [
  {
    number: 1,
    type: 'Sencilla',
    status: 'free',
  },
  {
    number: 2,
    type: 'Sencilla',
    status: 'occupied',
  },
  {
    number: 3,
    type: 'Sencilla',
    status: 'dirty',
  },
  {
    number: 4,
    type: 'Sencilla',
    status: 'unavailable',
  },
  {
    number: 5,
    type: 'Sencilla',
    status: 'free',
  },
  {
    number: 6,
    type: 'Sencilla',
    status: 'free',
  },
  {
    number: 7,
    type: 'Sencilla',
    status: 'free',
  },
  {
    number: 8,
    type: 'Sencilla',
    status: 'free',
  },
  {
    number: 9,
    type: 'Sencilla',
    status: 'free',
  },
  {
    number: 10,
    type: 'Jacuzzi',
    status: 'free',
  },
  {
    number: 11,
    type: 'Junior',
    status: 'free',
  },
  {
    number: 12,
    type: 'Junior',
    status: 'free',
  },
  {
    number: 14,
    type: 'Junior',
    status: 'free',
  },
  {
    number: 15,
    type: 'Junior',
    status: 'free',
  },
  {
    number: 16,
    type: 'Junior',
    status: 'free',
  },
  {
    number: 17,
    type: 'Junior',
    status: 'free',
  },
];
