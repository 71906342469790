import Axios, { AxiosError, AxiosInstance, Method } from 'axios';
import { makeUseAxios } from 'axios-hooks';

const { hostname, port } = window.location;
const apiUrl = process.env.REACT_APP_API_URL;
console.log(process.env);
const axios = Axios.create({
  baseURL: `http://${apiUrl}:3000/api`,
  headers: {
    authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
});

export const updateToken = (accessToken) => {
  localStorage.setItem('accessToken', accessToken);
  axios.defaults.headers.authorization = `Bearer ${accessToken}`;
};

export const getToken = () => {
  return (
    axios.defaults.headers.authorization?.replace('Bearer ', '') || undefined
  );
};

export const useAxios = makeUseAxios({ axios });

export default axios;
