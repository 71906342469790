import { default as logo } from '../../assets/logomotel.jpg';
import { WINDOW_HEIGHT } from '../../utils/globals';
import { Form, Button, Container, Row, Image, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import axios, { updateToken } from '../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../utils/redux/slices/userSlice';
import { initiateAlert } from '../../utils/redux/slices/alertSlice';

export default function Home() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  async function submit() {
    try {
      const response = await axios.post('/auth', { username, password });
      if (response.data) {
        updateToken(response.data.accessToken);
        dispatch(
          login({
            username,
            role: response.data.role,
            accessToken: response.data.accessToken,
          }),
        );
      } else
        dispatch(
          initiateAlert({
            text: 'Error desconocido. Contacte al encargado',
            variant: 'danger',
          }),
        );
    } catch (e) {
      if (e.response.status === 401)
        dispatch(
          initiateAlert({ text: 'Datos incorrectos', variant: 'danger' }),
        );
      else
        dispatch(
          initiateAlert({
            text: 'Error desconocido. Contacte al encargado',
            variant: 'danger',
          }),
        );
    }
  }
  return (
    <Container style={{ paddingTop: 100 }}>
      <Row>
        <Col></Col>
        <Col lg xs={100}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 50,
            }}
          >
            <img style={style.logo} src={logo} />
          </div>
          <Form>
            <Form.Control
              type="text"
              placeholder="Usuario"
              autoCapitalize="none"
              autoCorrect="none"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{ marginBottom: 10 }}
            />
            <Form.Control
              type="password"
              placeholder="contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ marginBottom: 20 }}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="warning"
                style={{ width: 100 }}
                onClick={async () => await submit()}
              >
                Entrar
              </Button>
            </div>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}

const style = {
  logo: {
    height: 200,
  },
};
