import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import alertReducer from './slices/alertSlice';
import { combineReducers, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

const myPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['alert'],
  whitelist: ['user'],
};

const myReducers = combineReducers({
  user: userReducer,
  alert: alertReducer,
});

const myPersistedReducer = persistReducer(myPersistConfig, myReducers);

export const myStore = configureStore({
  reducer: myPersistedReducer,
  middleware: [thunk],
});

export const myPersistor = persistStore(myStore);
